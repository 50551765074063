<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.weeks[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <div class="content" style="display: flex">
        <h4>{{ i18n.search[iso] }}:</h4>
        <b-button
          v-show="disabledAllFind"
          outlined
          style="margin-left: 10px"
          icon-left="sync"
          icon-pack="fas"
          size="is-small"
          type="is-info"
          @click="clearFindWeeks"
          >{{ i18n.resetSearch[iso] }}</b-button
        >
      </div>
      <div class="columns">
        <div class="column is-2">
          <b-field :label="i18n.invetoryType[iso]">
            <b-select
              v-model="invType"
              @input="listCountries"
              :disabled="disabledAllFind"
              expanded
            >
              <option :value="'all'">{{ i18n.all[iso] }}</option>
              <option v-for="(item, idx) in invTypes" :value="item" :key="idx">
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="i18n.country[iso]">
            <b-select
              v-model="country"
              @input="listStates"
              :disabled="countries.length === 0 || disabledAllFind"
              expanded
            >
              <optgroup
                :label="i18n.bestSellers[iso]"
                v-show="countriesFav.length > 0"
              >
                <option
                  v-for="(item, idxx) in countriesFav"
                  :value="item.Country"
                  :key="idxx"
                >
                  {{ item.Country }}
                </option>
              </optgroup>
              <option
                v-for="(item, idx) in countries"
                :value="item.Country"
                :key="idx"
              >
                {{ item.Country }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="i18n.state[iso]">
            <b-select
              v-model="state"
              :disabled="states.length === 0 || disabledAllFind"
              @input="listCities"
              expanded
            >
              <option
                v-for="(item, idx) in states"
                :value="item.CODIGO"
                :key="idx"
              >
                {{ item.NOMBRE_ESTADO }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="i18n.city[iso]">
            <b-select
              v-model="city"
              :disabled="cities.length === 0 || disabledAllFind"
              @input="listMonths"
              expanded
            >
              <option :value="'all'">Todas las ciudades</option>
              <option
                v-for="(item, idx) in cities"
                :value="item.City"
                :key="idx"
              >
                {{ item.City }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="CheckIn">
            <b-select
              v-model="checkInSelected"
              :disabled="checkIn.length === 0 || disabledAllFind"
              @input="setCheckOut"
              expanded
            >
              <option v-for="(chi, idx) in checkIn" :value="chi" :key="idx">
                {{ chi }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="CheckOut">
            <b-select
              v-model="checkOutSelected"
              :disabled="checkOut.length === 0 || disabledAllFind"
              @input="listWeeks"
              expanded
            >
              <option v-for="(cho, idx) in checkOut" :value="cho" :key="idx">
                {{ cho }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <b-progress format="percent" :max="100" size="is-small" show-value>
        <template #bar>
          <b-progress-bar :value="country ? 25 : 0" type="is-success">{{
            country && i18n.country[iso]
          }}</b-progress-bar>
          <b-progress-bar :value="city ? 25 : 0" type="is-success">{{
            city && i18n.city[iso]
          }}</b-progress-bar>
          <b-progress-bar :value="checkInSelected ? 25 : 0" type="is-success">{{
            checkInSelected && "CheckIn"
          }}</b-progress-bar>
          <b-progress-bar
            :value="checkOutSelected ? 25 : 0"
            type="is-success"
            >{{ checkOutSelected && "CheckOut" }}</b-progress-bar
          >
        </template>
      </b-progress>

      <hr />

      <div class="content">
        <h4>{{ i18n.filters[iso] }}:</h4>
      </div>

      <div class="columns">
        <div class="column is-4">
          <b-field :type="search && search !== '' ? 'is-info' : ''">
            <!-- <b-input
              :disabled="search || search === '' ? false : true"
              placeholder="Buscar por resort..."
              type="search"
              icon="magnify"
              v-model="search"
              @input="findWithFiltersWeeks"
            >
            </b-input> -->
            <b-autocomplete
              v-model="search"
              :data="filteredDataArray"
              :placeholder="i18n.findByResort[iso] + '...'"
              icon="magnify"
              clearable
              :open-on-focus="true"
              @select="(option) => (resortSelectedSearch = option)"
              @input="findWithFiltersWeeks"
            >
              <template #empty>{{ i18n.noResultsFound[iso] }}</template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :type="size ? 'is-info' : ''">
            <b-select
              :disabled="listSize.length > 0 ? false : true"
              placeholder="Size"
              v-model="size"
              @input="findWithFiltersWeeks"
              expanded
            >
              <option :value="null">-</option>
              <option v-for="(item, idx) in listSize" :value="item" :key="idx">
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :type="kitchen ? 'is-info' : ''">
            <b-select
              :disabled="listKitchen.length > 0 ? false : true"
              placeholder="Kitchen"
              v-model="kitchen"
              @input="findWithFiltersWeeks"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listKitchen"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :type="occupancy ? 'is-info' : ''">
            <b-select
              :disabled="listOccupancy.length > 0 ? false : true"
              placeholder="Occupancy"
              v-model="occupancy"
              @input="findWithFiltersWeeks"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listOccupancy"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :type="weekType ? 'is-info' : ''">
            <b-select
              :disabled="listWeekType.length > 0 ? false : true"
              placeholder="WeekType"
              v-model="weekType"
              @input="findWithFiltersWeeks"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listWeekType"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <b-table
        :data="isFilters ? searchWeeks : weeks"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :bordered="isBordered"
        :striped="isStriped"
        :narrowed="isNarrowed"
        :hoverable="isHoverable"
        :loading="isLoading"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
      >
        <b-table-column field="UnitCount" label="Availability" v-slot="props">
          {{ props.row.UnitCount || "n/a" }}
        </b-table-column>

        <b-table-column
          field="ResortName"
          label="Resort Name"
          sortable
          v-slot="props"
        >
          <b-tooltip type="is-info">
            <b-tag
              type="is-info is-light"
              style="cursor: pointer"
              @click="openModalResort(props.row)"
              ><b>{{ props.row.ResortName }}</b></b-tag
            >
            <template v-slot:content> <b>Detalles del Resort</b></template>
          </b-tooltip>

          <b-tooltip
            type="is-warning"
            v-show="props.row.ImportantNotesSP || props.row.ImportantNotesSP"
          >
            <b-tag
              type="is-warning"
              style="cursor: pointer"
              @click="
                openImportNotes(
                  iso === 'es'
                    ? props.row.ImportantNotesSP
                    : props.row.ImportantNotesEN
                )
              "
              ><b-icon icon="sticky-note" pack="fas" size="is-small"> </b-icon
            ></b-tag>
            <template v-slot:content> <b>Nota importante</b></template>
          </b-tooltip>
        </b-table-column>

        <b-table-column field="Location" label="Location" v-slot="props">
          {{ props.row.ResortCity }}
        </b-table-column>

        <b-table-column field="State" label="State" v-slot="props">
          {{ props.row.ResortState }}
        </b-table-column>

        <b-table-column field="Country" label="Country" v-slot="props">
          {{ props.row.ResortCountry }}
        </b-table-column>

        <b-table-column
          field="CheckInDate"
          label="CheckIn"
          centered
          sortable
          v-slot="props"
        >
          {{ dateToString(props.row.CheckInDate) }}
        </b-table-column>

        <b-table-column
          field="CheckOutDate"
          label="CheckOut"
          sortable
          centered
          v-slot="props"
        >
          {{ dateToString(props.row.CheckOutDate) }}
        </b-table-column>

        <b-table-column
          label="Size / Kitchen / Max. Occupancy"
          centered
          v-slot="props"
        >
          {{ props.row.UnitType }} / {{ props.row.KitchenType }} /
          {{ props.row.MaxOccupancy }}
        </b-table-column>

        <b-table-column field="Price" label="Cost" sortable v-slot="props">
          $ {{ props.row.Price }}
        </b-table-column>

        <b-table-column field="priceReal" label="Price" sortable v-slot="props">
          $ {{ props.row.priceReal }}
        </b-table-column>

        <b-table-column
          field="InvTypeInDetail"
          label="Week Type"
          v-slot="props"
        >
          {{ props.row.InvTypeInDetail }}
        </b-table-column>

        <b-table-column
          label="Actions"
          v-slot="props"
          width="100"
          v-if="
            $checkTotalPermissionsAvailable(['hold-week', 'hold-fast-week'])
          "
        >
          <b-tooltip type="is-success" v-if="$checkPermission('hold-week')">
            <b-button
              @click="openModalHoldFull(props.row.InventoryID)"
              icon-left="paper-plane"
              icon-pack="fas"
              size="is-small"
              type="is-success"
              style="margin-right: 10px"
            ></b-button>
            <template v-slot:content> <b>Hold Full</b></template>
          </b-tooltip>
          <b-tooltip
            type="is-warning"
            v-if="$checkPermission('hold-fast-week')"
          >
            <b-button
              @click="holdFast(props.row.InventoryID)"
              icon-left="fire"
              icon-pack="fas"
              size="is-small"
              type="is-warning"
            ></b-button>
            <template v-slot:content> <b>Hold Fast</b></template>
          </b-tooltip>
        </b-table-column>
        <template #footer>
          <div class="columns">
            <div class="column is-11"></div>
            <div class="column is-1">
              <b-field :label="i18n.quantity[iso]">
                <b-select v-model="perPage" @input="savePerPage" expanded>
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <!-- <div class="has-text-right">Footer</div> -->
        </template>
      </b-table>
    </section>
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-label="Hold"
      aria-modal
    >
      <template #default="props">
        <modal-hold
          @close="props.close"
          @refresh="listWeeks"
          :countries="countries"
          :weeks="weeks"
          :inventoryId="inventoryId"
          mode="hold"
        ></modal-hold>
      </template>
    </b-modal>
    <b-modal
      v-model="isComponentModalActiveResort"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-label="Resort"
      aria-modal
    >
      <template #default="props">
        <modal-resort @close="props.close" :week="weekResort"></modal-resort>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import ModalHold from "./ModalHold.vue";
import ModalResort from "./ModalViewResort.vue";
import moment from "moment";
import i18n from "../utils/i18n";

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,

      //

      weeks: [],
      invType: null,
      // invType: "all",
      invTypes: ["Last Minute Week", "Smart Week", "VIP Week"],
      countries: [],
      // country: "USA",
      country: null,
      countriesFav: [],
      state: null,
      states: [],
      cities: [],
      // city: "Orlando",
      city: null,
      months: [],
      checkIn: [],
      // checkInSelected: "2024-01-01",
      checkInSelected: null,
      checkOut: [],
      // checkOutSelected: "2024-03-30",
      checkOutSelected: null,

      travelerInfo: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        address: null,
        city: null,
        state: null,
        country: null,
        zipcode: null,
      },
      inventoryId: null,

      //Modal
      isComponentModalActive: false,
      isComponentModalActiveResort: false,
      weekResort: {},

      //Filters
      isFilters: false,
      search: null,
      searchWeeks: [],
      size: null,
      listSize: [],
      kitchen: null,
      listKitchen: [],
      occupancy: null,
      listOccupancy: [],
      weekType: null,
      listWeekType: [],

      //Autocomplete Search
      searchResorts: [],
      resortSelectedSearch: null,

      //Temp
      disabledAllFind: false,

      //i18n
      i18n,
      iso: null,
    };
  },
  created() {
    // this.listCountries();
    this.listWeeks();

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageWeeks");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;
  },
  components: {
    ModalHold,
    ModalResort,
  },
  computed: {
    filteredDataArray() {
      if (this.search && this.search !== "")
        return this.searchResorts.filter((option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
          );
        });
      else return this.searchResorts;
    },
  },
  // watch: {
  //   firstName: function (val) {
  //     this.fullName = val + ' ' + this.lastName
  //   },
  // },
  methods: {
    listCountries() {
      axios({
        method: "get",
        url: `${API_URL}/week/countries`,
        headers: {
          iso: this.iso,
        },
      })
        .then(({ data }) => {
          console.log(data);
          this.countries = data.countries;
          this.countriesFav = this.countries.filter(
            (c) =>
              c.Country === "Dominican Republic" ||
              c.Country === "Mexico" ||
              c.Country === "USA"
          );
        })
        .catch((err) => {
          console.log(err);
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        });
    },
    listStates() {
      if (this.country) {
        const _country = this.countries.find((c) => c.Country === this.country);
        console.log(_country);

        if (_country && _country.TIPO === 4) {
          axios({
            method: "get",
            url: `${API_URL}/week/states?country=${this.country}`,
            headers: {
              iso: this.iso,
            },
          })
            .then(({ data }) => {
              console.log(data);
              this.states = data.states;
            })
            .catch((err) => {
              console.log(err);
              this.$buefy.toast.open({
                message: "Error al consultar datos",
                type: "is-danger",
              });
            });
        } else {
          this.listCities();
        }
      }
    },
    listCities() {
      if (this.country) {
        axios({
          method: "get",
          url: `${API_URL}/week/cities?country=${this.country}${
            this.state ? "&state=" + this.state : ""
          }`,
          headers: {
            iso: this.iso,
          },
        })
          .then(({ data }) => {
            console.log(data);
            this.cities = data.cities;
            this.checkIn = [];
            this.checkOut = [];
            this.checkInSelected = null;
            this.checkOutSelected = null;
          })
          .catch((err) => {
            console.log(err);
            this.$buefy.toast.open({
              message: "Error al consultar datos",
              type: "is-danger",
            });
          });
      }
    },
    listMonths() {
      if (this.country && this.city) {
        axios({
          method: "get",
          url: `${API_URL}/week/check-months?country=${this.country}&city=${
            this.city
          }${this.state ? "&state=" + this.state : ""}`,
          headers: {
            iso: this.iso,
          },
        })
          .then(({ data }) => {
            console.log(data);
            this.months = data;
            this.checkIn = data.checkIn;

            if (this.checkIn.length === 0) {
              this.$buefy.toast.open({
                message: "No hay semanas disponibles para dicha ciudad",
                type: "is-warning",
                delay: 2000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$buefy.toast.open({
              message: "Error al consultar datos",
              type: "is-danger",
            });
          });
      }
    },
    setCheckOut() {
      this.checkOut = this.months.checkOut;
    },
    listWeeks() {
      if (
        this.country &&
        this.city &&
        this.checkInSelected &&
        this.checkOutSelected &&
        this.invType
      ) {
        this.isLoading = true;
        axios({
          method: "post",
          url: `${API_URL}/week/list`,
          data: {
            country: this.country,
            city: this.city,
            dateIn: this.checkInSelected,
            dateOut: this.checkOutSelected,
            InvTypeInDetail: this.invType,
            state: this.state || undefined,
          },
          headers: {
            iso: this.iso,
          },
        })
          .then(({ data }) => {
            console.log(data);
            this.weeks = data.weeks;

            this.disabledAllFind = true;

            //Filters
            this.search = null;
            this.resortSelectedSearch = null;
            this.searchResorts = [
              ...new Set(
                this.weeks
                  .map((w) => w.ResortName)
                  .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
              ),
            ];
            this.listSize = [
              ...new Set([...this.weeks.map((w) => w.UnitType)]),
            ].sort();
            this.listKitchen = [
              ...new Set([...this.weeks.map((w) => w.KitchenType)]),
            ].sort();
            this.listOccupancy = [
              ...new Set([...this.weeks.map((w) => w.MaxOccupancy)]),
            ].sort();
            this.listWeekType = [
              ...new Set([...this.weeks.map((w) => w.InvTypeInDetail)]),
            ].sort();

            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
            this.$buefy.toast.open({
              message: "Error al consultar datos",
              type: "is-danger",
            });
          });
      }
    },
    selectedForHold(inventoryId) {
      this.inventoryId = inventoryId;
    },
    holdFast(inventoryId) {
      this.$buefy.dialog.confirm({
        title: "Reserva rapida",
        message:
          "Al momento de emitir el pago deberas llenar todos los datos necesarios del viajero.",
        confirmText: "Lo entiendo!",
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            const { data } = await axios.post(
              `${API_URL}/week/hold-fast`,
              {
                inventoryId,
                createdBy: localStorage.getItem("user"),
              },
              { headers: { iso: this.iso } }
            );
            console.log(data);
            this.$buefy.toast.open({
              message: "Reserva rapida realizada",
              type: "is-success",
            });
          } catch (error) {
            console.log(error);
            this.$buefy.toast.open({
              message: "Error al realizar reserva rapida",
              type: "is-danger",
            });
            // this.$buefy.toast.open({
            //   message: error,
            //   type: "is-danger",
            // });
          }
        },
      });
    },
    openModalHoldFull(inventoryId) {
      this.inventoryId = inventoryId;
      this.isComponentModalActive = true;
    },
    openModalResort(week) {
      this.weekResort = week;
      this.isComponentModalActiveResort = true;
    },
    findWithFiltersWeeks() {
      // console.log(this.resortSelectedSearch);
      // console.log(this.search);
      let resultWeeks = this.weeks;

      if (
        this.resortSelectedSearch &&
        this.resortSelectedSearch.trim() !== ""
      ) {
        resultWeeks = resultWeeks.filter((w) => {
          const rgx = new RegExp(this.search, "gi");
          if (rgx.test(w.ResortName)) return w;
        });
      }
      if (this.size)
        resultWeeks = resultWeeks.filter((w) => w.UnitType === this.size);
      if (this.kitchen)
        resultWeeks = resultWeeks.filter((w) => w.KitchenType === this.kitchen);
      if (this.occupancy)
        resultWeeks = resultWeeks.filter(
          (w) => w.MaxOccupancy === this.occupancy
        );
      if (this.weekType)
        resultWeeks = resultWeeks.filter(
          (w) => w.InvTypeInDetail === this.weekType
        );

      if (this.resortSelectedSearch) {
        if (
          (this.resortSelectedSearch.trim() === "" ||
            !this.resortSelectedSearch) &&
          !this.size &&
          !this.kitchen &&
          !this.occupancy &&
          !this.weekType
        ) {
          this.isFilters = false;
        } else {
          this.isFilters = true;
          this.searchWeeks = resultWeeks;
        }
      } else {
        if (!this.size && !this.kitchen && !this.occupancy && !this.weekType) {
          this.isFilters = false;
        } else {
          this.isFilters = true;
          this.searchWeeks = resultWeeks;
        }
      }
    },
    sortDate(a, b, c) {
      // console.log('asasd')
      // console.log(a)
      // console.log(b)
      // console.log(c)
    },
    openImportNotes(message) {
      this.$buefy.dialog.confirm({
        title: "Nota importante",
        message,
        cancelText: "Cerrar",
        confirmText: "OK",
        type: "is-warning",
        onConfirm: () => this.$buefy.toast.open("Perfecto, has leido la nota"),
      });
    },
    dateToString(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    checkAllFindWeeks() {
      if (
        this.country &&
        this.city &&
        this.checkInSelected &&
        this.checkOutSelected &&
        this.invType &&
        this.state
      )
        return true;
      else false;
    },
    clearFindWeeks() {
      this.country = null;
      this.countries = [];
      this.state = null;
      this.states = [];
      this.city = null;
      this.cities = [];
      this.checkInSelected = null;
      this.checkIn = [];
      this.checkOutSelected = null;
      this.checkOut = [];
      this.invType = null;
      this.weeks = [];
      this.disabledAllFind = false;
    },
    savePerPage() {
      localStorage.setItem("perPageWeeks", this.perPage);
    },
  },
};
</script>
